import React from 'react';
import { PageLayout } from '../components/pageLayout';
import {
	Flex,
	Heading,
	Text,
	Box,
	css,
	Image,
	FlexProps,
} from '@chakra-ui/core';
import { graphql } from 'gatsby';
import { BlogPreviewPanel } from '../components/BlogPreviewPanel';
import { CTALink } from '../components/ctaLink';

interface IFeatureImageHeader {
	title: string;
	bodyText?: string;
	ctaLink?: URL | string;
	ctaLinkText?: string;
	featureImageUrl?: URL | string;
	rest?: FlexProps;
}

export const FeatureImageHeader = ({
	title,
	bodyText,
	ctaLink,
	ctaLinkText,
	featureImageUrl,
	...rest
}: IFeatureImageHeader) => {
	return (
		<Flex
			flexDir={{ base: 'column', lg: 'row' }}
			w="100%"
			bg="#f7f0e3"
			alignItems="center"
			mb={10}
			{...rest}
		>
			<Image
				src={featureImageUrl}
				mt={{ base: 20, lg: 0 }}
				w={{ base: '80%', lg: '50%' }}
			/>
			<Box
				px={{ base: 0, lg: 20 }}
				my={{ base: 10, lg: 0 }}
				w={{ base: '80%', lg: '50%' }}
				css={css`
					h1 {
						font-size: 3rem;
						font-weight: 700;
					}
					max-width: 75ch;
				`}
			>
				<h1>{title}</h1>
				{bodyText}
				{ctaLink && (
					<CTALink
						d="block"
						text={ctaLinkText || 'Read Article'}
						href={ctaLink}
					/>
				)}
				{/* <StyledRichText render={el.text} linkResolver={linkResolver} /> */}
			</Box>
		</Flex>
	);
};
